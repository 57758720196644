.__dropdown .p-dropdown-label {
  display: flex !important;
  align-items: center;
}

.my-editor-class .ql-editor {
  font-size: 16px;
}

.mention {
  color: #1769aa;
  background-color: #a0d2fa;
  padding: 2px 5px;
  border-radius: 5px;
}

.emoji-container {
  top: 35px;
}